module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://octet.design","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/images/favicon.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0faddfac2977fcdff7e637a8e7831c17"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://octet-gatsby.in2.cdn-alpha.com/graphql","protocol":"https","hostingWPCOM":false,"useACF":true,"acfOptionPageIds":[],"verboseOutput":false,"perPage":10,"concurrentRequests":10,"includedRoutes":["**/posts","**/pages","**/media","**/categories","**/tags","**/taxonomies","**/users"],"develop":{"nodeUpdateInterval":300,"hardCacheMediaFiles":false,"hardCacheData":false},"timeout":100000000000,"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
